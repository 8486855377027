import React, { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { isEmpty, isEmail } from "../helper/validate";
import { useSelector } from 'react-redux'
import {Helmet} from 'react-helmet'

const ContactForm = () => {
  const { user } = useSelector((state) => state.auth)
  const [formData, setFormData] = useState({
    email: "",
    message: "",
  });
  if(user){
    formData.email = user.email
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (isEmpty(formData.email))
      return toast.warning("Please fill in all fields.", {

      });
    if (isEmpty(formData.message))
        return toast.warning("Please fill in all fields.", {
    });
    if (!isEmail(formData.email))
      return toast.warning("Please enter a valid email address.", {
      });
    if(formData.email.length > 100)
    return toast.warning("Email too long.", {
    });
    if(formData.message.length > 600)
    return toast.warning("Message field too long.", {
    });
    


    
    
    // Here you can send the form data to your server or do something else with it
    console.log(formData);

    try {
        await axios.post("/api/users/contactform", { formData });
        setFormData({ ...formData, email: "" });
        setFormData({ ...formData, message: "" });
        formData.message = ""
        return toast.success("Message has been sent.", {position: toast.POSITION.TOP_CENTER});
      } catch (err) {
        toast(err.response.data.msg, {
        });
      }
  };

  return (
    <>
    <Helmet>
    <title>Contact | WildPermits</title>
    <meta name='Contact' content='Contact page for WildPermits.' />
    </Helmet>
    <div className='formcard'>
    <h4>
            Contact Form
        </h4>
        <p className="pinfo">I appreciate any feedback. All messages sent here go to my inbox. Though if you prefer to reach out directly, email support@wildpermits.com.</p>
        <br></br>
    <form onSubmit={handleSubmit}>
    <div className='inputreg'>
        <input
          className="input-fields"
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          required
        />
        <label className="input-labels">Email</label>
      </div>
      <div className='inputreg'>
        <textarea
          className="messagebox"
          rows="11" cols="28"
          id="message"
          name="message"
          placeholder="Type your message"
          value={formData.message}
          onChange={handleInputChange}
        />

      </div>
      <button type="submit" className="btn2">Send</button>
    </form>
    </div>
    </>
  );
};

export default ContactForm;