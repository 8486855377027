import { useDispatch } from 'react-redux'
import { deleteGoal } from '../features/goals/goalSlice'
import React from 'react';
import { useState } from 'react'
import MediaQuery from 'react-responsive'

function WeatherWidget({ weather }) {
  console.log(weather)
  let typeDic = {
    0: "Clear sky",
    1: "Mainly clear",
    2: "Partly Cloudy",
    3: "Overcast",
    45: "Fog",
    48: "Fog",
    51: "Drizzle: Light",
    53: "Drizzle: moderate",
    55: "Drizzle: dense",
    56: "Freezing Drizzle: light",
    57: "Freezing Drizzle: dense",
    61: "Rain: Slight",
    63: "Rain: Moderate",
    65: "Rain: Heavy",
    66: "Freezing Rain: Light",
    67: "Freezing Rain: Heavy",
    71: "Snow: Light",
    73: "Snow: Moderate",
    75: "Snow: Heavy",
    77: "Snow grains",
    80: "Rain Showers: Slight",
    81: "Rain Showers: Moderate",
    82: "Rain Showers: Violent",
    85: "Snow Showers: Light",
    86: "Snow Showers: Heavy",
    95: "ThunderStorms possible",
    96: "ThunderStorms possible",
    99: "ThunderStorms possible",
  }
  console.log(typeDic[81])
  console.log("hello")

  function getDayName(dateStr, locale)
{
    var date = new Date(dateStr);
    console.log(date)
    return date.toLocaleDateString("en-US",  { timeZone: 'UTC'});        
}

let icon = {
    0: "clear",
    1: "partlycloudy",
    2: "partlycloudy",
    3: "overcast",
    45: "fog",
    48: "fog",
    51: "rainlight",
    53: "rainheavy",
    55: "rainheavy",
    56: "rainheavy",
    57: "rainheavy",
    61: "rainlight",
    63: "rainheavy",
    65: "rainheavy",
    66: "rainheavy",
    67: "rainheavy",
    71: "snowlight", 
    73: "snowheavy",
    75: "snowheavy",
    77: "snowlight",
    80: "rainlight",
    81: "rainheavy",
    82: "rainheavy",
    85: "snowlight",
    86: "snowheavy",
    95: "rainthunder",
    96: "rainthunder",
    99: "rainthunder",
  }




  return (
    <>
    <MediaQuery maxWidth={600}>
    <div className="flexweather-mobile">
    <div className="weathertitlemobile">
    {weather.name.replace(/[0-9]/g, '')}
    <br></br>
    {weather.tag}
    <br></br>
    Elevation: {weather.elevation} Feet
    </div>
      <div className="interiorweathermobile">
      <div className="weathergridmain">
      <h1 className="weathernamemobile">{getDayName(weather.daily[0])}</h1>
      <div className="weathericon-mobile">
      <div className={icon[weather.weathercode[0]]}></div>
      </div>
      {typeDic[weather.weathercode[0]]}
      <br></br>
      Precip: {weather.precipmax[0]}%
      </div>
      <div className="weathergriditem">
      <div className="hi">Hi: {weather.temperature_max[0]}°F
      <br></br>
      Lo: {weather.temperature_min[0]}°F
      </div>
      </div>
      <div className="weathergriditem">
      Wind: {weather.windspeed[0]} mph
      Gusts: {weather.windgusts[0]} mph
      </div>

      </div>
      <div className="interiorweathermobile">
      <div className="weathergridmain">
      <h1 className="weathernamemobile">{getDayName(weather.daily[1])}</h1>
      <div className="weathericon-mobile">
      <div className={icon[weather.weathercode[1]]}></div>
      </div>
      {typeDic[weather.weathercode[1]]}
      <br></br>
      Precip: {weather.precipmax[1]}%
      </div>
      <div className="weathergriditem">
      <div className="hi">Hi: {weather.temperature_max[1]}°F
      <br></br>
      Lo: {weather.temperature_min[1]}°F
      </div>
      </div>
      <div className="weathergriditem">
      Wind: {weather.windspeed[1]} mph
      Gusts: {weather.windgusts[1]} mph
      </div>
      </div>
      <div className="interiorweathermobile">
      <div className="weathergridmain">
      <h1 className="weathernamemobile">{getDayName(weather.daily[2])}</h1>
      <div className="weathericon-mobile">
      <div className={icon[weather.weathercode[2]]}></div>
      </div>
      {typeDic[weather.weathercode[2]]}
      <br></br>
      Precip: {weather.precipmax[2]}%
      </div>
      <div className="weathergriditem">
      <div className="hi">Hi: {weather.temperature_max[2]}°F
      <br></br>
      Lo: {weather.temperature_min[2]}°F
      </div>
      </div>
      <div className="weathergriditem">
      Wind: {weather.windspeed[2]} mph
      Gusts: {weather.windgusts[2]} mph
      </div>
      </div>
      <div className="interiorweathermobile">
      <div className="weathergridmain">
      <h1 className="weathernamemobile">{getDayName(weather.daily[3])}</h1>
      <div className="weathericon-mobile">
      <div className={icon[weather.weathercode[3]]}></div>
      </div>
      {typeDic[weather.weathercode[3]]}
      <br></br>
      Precip: {weather.precipmax[3]}%
      </div>
      <div className="weathergriditem">
      <div className="hi">Hi: {weather.temperature_max[3]}°F
      <br></br>
      Lo: {weather.temperature_min[3]}°F
      </div>
      </div>
      <div className="weathergriditem">
      Wind: {weather.windspeed[3]} mph
      Gusts: {weather.windgusts[3]} mph
      </div>
      </div>
      <div className="interiorweathermobile">
      <div className="weathergridmain">
      <h1 className="weathernamemobile">{getDayName(weather.daily[4])}</h1>
      <div className="weathericon-mobile">
      <div className={icon[weather.weathercode[4]]}></div>
      </div>
      {typeDic[weather.weathercode[4]]}
      <br></br>
      Precip: {weather.precipmax[4]}%
      </div>
      <div className="weathergriditem">
      <div className="hi">Hi: {weather.temperature_max[4]}°F
      <br></br>
      Lo: {weather.temperature_min[4]}°F
      </div>
      </div>
      <div className="weathergriditem">
      Wind: {weather.windspeed[4]} mph
      Gusts: {weather.windgusts[4]} mph
      </div>
      </div>
      <div className="interiorweathermobile">
      <div className="weathergridmain">
      <h1 className="weathernamemobile">{getDayName(weather.daily[5])}</h1>
      <div className="weathericon-mobile">
      <div className={icon[weather.weathercode[5]]}></div>
      </div>
      {typeDic[weather.weathercode[5]]}
      <br></br>
      Precip: {weather.precipmax[5]}%
      </div>
      <div className="weathergriditem">
      <div className="hi">Hi: {weather.temperature_max[5]}°F
      <br></br>
      Lo: {weather.temperature_min[5]}°F
      </div>
      </div>
      <div className="weathergriditem">
      Wind: {weather.windspeed[5]} mph
      Gusts: {weather.windgusts[5]} mph
      </div>
      </div>
      <div className="interiorweathermobile">
      <div className="weathergridmain">
      <h1 className="weathernamemobile">{getDayName(weather.daily[6])}</h1>
      <div className="weathericon-mobile">
      <div className={icon[weather.weathercode[6]]}></div>
      </div>
      {typeDic[weather.weathercode[6]]}
      <br></br>
      Precip: {weather.precipmax[6]}%
      </div>
      <div className="weathergriditem">
      <div className="hi">Hi: {weather.temperature_max[6]}°F
      <br></br>
      Lo: {weather.temperature_min[6]}°F
      </div>
      </div>
      <div className="weathergriditem">
      Wind: {weather.windspeed[6]} mph
      Gusts: {weather.windgusts[6]} mph
      </div>
      </div>
    </div>
    </MediaQuery>
    <MediaQuery minWidth={601}>
    <div className="center-weather">
    <div className="flexcontainerweather">
    <div className="weathertitle">
    {weather.name.replace(/[0-9]/g, '')}
    <br></br>
    {weather.tag}
    <br></br>
    Elevation: {weather.elevation} Feet
    </div>
      <div className="interiorweathertoday">
      <h1 className="weathername">{getDayName(weather.daily[0])}</h1>
      <div className="weathericon">
      <div className={icon[weather.weathercode[0]]}></div>
      </div>
        {typeDic[weather.weathercode[0]]}
        <br></br>
      Precip: {weather.precipmax[0]}%
      <br></br>
      Hi: {weather.temperature_max[0]}°F
      <br></br>
      Lo: {weather.temperature_min[0]}°F
      <br></br>
      Wind: {weather.windspeed[0]} mph
      <br></br>
      Gusts: {weather.windgusts[0]} mph
      <br></br>
      </div>
      
      <div className="interiorweather">
      <h1 className="weathernamesmall">{getDayName(weather.daily[1])}</h1>
      <div className="weathericon">
      <div className={icon[weather.weathercode[1]]}></div>
      </div>
        {typeDic[weather.weathercode[1]]}
        <br></br>
        Precip: {weather.precipmax[1]}%
      <br></br>
      Hi: {weather.temperature_max[1]}°F
      <br></br>
      Lo: {weather.temperature_min[1]}°F
      <br></br>
      Wind: {weather.windspeed[1]} mph
      <br></br>
      Gusts: {weather.windgusts[1]} mph
      <br></br>
      </div>
      <div className="interiorweather">
      <h1 className="weathernamesmall">{getDayName(weather.daily[2])}</h1>
      <div className="weathericon">
      <div className={icon[weather.weathercode[2]]}></div>
      </div>
        {typeDic[weather.weathercode[2]]}
        <br></br>
        Precip: {weather.precipmax[2]}%
      <br></br>
      Hi: {weather.temperature_max[2]}°F
      <br></br>
      Lo: {weather.temperature_min[2]}°F
      <br></br>
      Wind: {weather.windspeed[2]} mph
      <br></br>
      Gusts: {weather.windgusts[2]} mph
      <br></br>
      </div>
      <div className="interiorweather">
      <h1 className="weathernamesmall">{getDayName(weather.daily[3])}</h1>
      <div className="weathericon">
      <div className={icon[weather.weathercode[3]]}></div>
      </div>
        {typeDic[weather.weathercode[3]]}
        <br></br>
        Precip: {weather.precipmax[3]}%
      <br></br>
      Hi: {weather.temperature_max[3]}°F
      <br></br>
      Lo: {weather.temperature_min[3]}°F
      <br></br>
      Wind: {weather.windspeed[3]} mph
      <br></br>
      Gusts: {weather.windgusts[3]} mph
      <br></br>
      </div>
      <div className="interiorweather">
      <h1 className="weathernamesmall">{getDayName(weather.daily[4])}</h1>
      <div className="weathericon">
      <div className={icon[weather.weathercode[4]]}></div>
      </div>
        {typeDic[weather.weathercode[4]]}
        <br></br>
        Precip: {weather.precipmax[4]}%
      <br></br>
      Hi: {weather.temperature_max[4]}°F
      <br></br>
      Lo: {weather.temperature_min[4]}°F
      <br></br>
      Wind: {weather.windspeed[4]} mph
      <br></br>
      Gusts: {weather.windgusts[4]} mph
      <br></br>
      </div>
      <div className="interiorweather">
      <h1 className="weathernamesmall">{getDayName(weather.daily[5])}</h1>
      <div className="weathericon">
      <div className={icon[weather.weathercode[5]]}></div>
      </div>
        {typeDic[weather.weathercode[5]]}
        <br></br>
        Precip: {weather.precipmax[5]}%
      <br></br>
      Hi: {weather.temperature_max[5]}°F
      <br></br>
      Lo: {weather.temperature_min[5]}°F
      <br></br>
      Wind: {weather.windspeed[5]} mph
      <br></br>
      Gusts: {weather.windgusts[5]} mph
      <br></br>
      </div>
      <div className="interiorweather">
      <h1 className="weathernamesmall">{getDayName(weather.daily[6])}</h1>
      <div className="weathericon">
      <div className={icon[weather.weathercode[6]]}></div>
      </div>
        {typeDic[weather.weathercode[6]]}
        <br></br>
        Precip: {weather.precipmax[6]}%
      <br></br>
      Hi: {weather.temperature_max[6]}°F
      <br></br>
      Lo: {weather.temperature_min[6]}°F
      <br></br>
      Wind: {weather.windspeed[6]} mph
      <br></br>
      Gusts: {weather.windgusts[6]} mph
      <br></br>
      </div>
    </div>
    </div>
    </MediaQuery>
    </>
  )
}

export default WeatherWidget
