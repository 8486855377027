import weatherService from './weatherService'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

const initialState = {
    weatherList: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
  }

export const getWeather = createAsyncThunk(
'conditions/getAll',
async (zone, thunkAPI) => {
    try {
    console.log(zone)
    return await weatherService.getWeather(zone)
    } catch (error) {
    const message =
        (error.response &&
        error.response.data &&
        error.response.data.message) ||
        error.message ||
        error.toString()
    return thunkAPI.rejectWithValue(message)
    }
}
)

export const weatherSlice = createSlice({
    name: 'weather',
    initialState,
    reducers: {
      reset: (state) => initialState,
    },
    extraReducers: (builder) => {
      builder
        .addCase(getWeather.pending, (state) => {
          state.isLoading = true
        })
        .addCase(getWeather.fulfilled, (state, action) => {
          state.isLoading = false
          state.isSuccess = true
          state.weatherList = action.payload
        })
        .addCase(getWeather.rejected, (state, action) => {
          state.isLoading = false
          state.isError = true
          state.weatherList = action.payload
        })
    },
  })
  
  export const { reset } = weatherSlice.actions
  export default weatherSlice.reducer