import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { createGoal } from '../features/goals/goalSlice'
//import DatePicker from "react-multi-date-picker"
import DatePanel from "react-multi-date-picker/plugins/date_panel"
import { DateObject } from "react-multi-date-picker";
import { Calendar } from "react-multi-date-picker"
import date_panel from 'react-multi-date-picker/plugins/date_panel';
import { toast } from 'react-toastify'
import { useEffect } from 'react'
import DatePicker from "react-multi-date-picker"
import opacity from "react-element-popper/animations/opacity"
import size from "react-element-popper/animations/size"
import transition from "react-element-popper/animations/transition"
import "react-multi-date-picker/styles/layouts/prime.css"
import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import {Button} from 'baseui/button';
import {ButtonGroup} from 'baseui/button-group';
import {Checkbox, Radio} from 'react-btn-checkbox';
import { MultiSelect } from "react-multi-select-component";
import {options} from '../components/list'
import {inyo} from '../components/inyo'
import {yosemite} from '../components/yosemite'
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import {reset } from '../features/goals/goalSlice'
import "react-multi-date-picker/styles/colors/green.css"
import "react-multi-date-picker/styles/layouts/mobile.css"
import { useMediaQuery } from 'react-responsive'
import MediaQuery from 'react-responsive'




function GoalForm() {
  
  //const today = new Date()
  //const tomorrow = new Date()
  //tomorrow.setDate(tomorrow.getDate() + 1)
  const format = "MM/DD/YYYY";
  const today = new Date();
  function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

  const isMobile = useMediaQuery({ query: '(max-width: 1000px)' });
  const gridcontainer = isMobile ? 'grid-container-mobile' : 'grid-container';
  const border = isMobile ? 'bordergrey-mobile' : 'bordergrey';
  const rmsc = isMobile ? 'rmsc-mobile' : 'rmsc';
  const permitgrid = isMobile ? 'permitgrid-mobile' : 'permitgrid';
  

  const changeHandler = e => {
    //e.preventDefault()
    //console.log(e)
    //console.log(e.value)
    setAllValues( prevValues => {
    return { ...prevValues,[e.name]: e.value}
    })
  }
  const [selected, setSelected] = useState("");
  const changeHandler2 = e => {
    setSelected(e.target.value);
  };

  const [values, setAllValues] = useState({
    dates: [],
    permits: [],
    num: '',
  });
  const { user } = useSelector((state) => state.auth)
  const { goals, isLoading, isSuccess, isError, message } = useSelector(
    (state) => state.goals
  )
  const dropOptions = [
    '1', '2', '3', '4', '5',
  ]
  const defaultOption = dropOptions[0];

  const {
    dates,
    permits,
    num,
  } = values

  function getStateButton() {
    if(!values.dates || !values.permits || !values.num || values.permits.length < 1 || values.dates.length < 1){
      return true;
    }
    return false;
  }

  const onChange = (e) => {
    values.num = e.target
    setAllValues( prevValues => {
      return { ...prevValues,[e.name]: e.value}
      })

  }
  function getDay(day) {
    const weekday = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];
    let date = day.toISOString().substring(0, 10);
    let dayname = weekday[day.getDay()];
    let finaldate = date + " (" + dayname + ")";
    return finaldate
  }
  function getPermit(permit) {
    console.log(permit)
    return permit.value
  }
  function getGroup() {
    return num.value
  }

  function handler(x) {
    // get the list of all images and convert the html collection to an array
    var imagesArray = Array.from(document.getElementsByClassName('displaydiv'))

    // cycle the array to show the one selected and hide all the others
    console.log(x)
    imagesArray.forEach(ele => {
      if(ele.id === x) {
        ele.style.display = 'block'
      } else {
        ele.style.display = 'none'
      }
    })
}

  //const value2 = new DateObject()
  const dispatch = useDispatch()
  useEffect(() => {
    if (isError) {
      toast.error(message)
    }
    else if (isSuccess){
      
    }
  
    //dispatch(reset())
  }, [goals, isError, isSuccess, message, dispatch])
  //var num2 = dispatch(getNumGoals())
  const onSubmit = (e) => {
    e.preventDefault()
    //values.toDate()
    //for (var i = 0; i < values.length; i++){
      //values[i] = values[i].toString()
      //console.log(values[i])
    //}
    //setDates(values)
    for(var i = 0; i < values.permits.length; i++){
      //console.log(typeof(values.permits.at(i).value))
      values.permits[i] = values.permits.at(i).value
    }

    for(var i = 0; i < values.dates.length; i++){
      //console.log(values.dates.at(i).toDate().toLocaleDateString("en-US"))
      values.dates[i] = values.dates.at(i).toDate()
      values.dates[i] = formatDate(values.dates.at(i))
      //console.log(values.dates[i])
    }

    values.num = parseInt(values.num.value) //convert

    
    console.log(values.dates)
    //console.log(e.target.dates)
    console.log(values.permits)
    console.log(typeof(values.num))
    console.log(values.num)

    if(values.dates.length > 0 && values.permits.length){
      console.log(values.num)
      dispatch(createGoal({ values }))
      values.dates = []
      values.permits = []
      values.num = ''
      document.getElementById("1").checked=false
      document.getElementById("2").checked=false
      document.getElementById("3").checked=false
      document.getElementById("4").checked=false
      document.getElementById("5").checked=false
    }
    else{
      toast.error("Please select a date and a permit.", {
        toastId: 'success1',
    })
    values.dates = []
      values.permits = []
      values.num = ''
      document.getElementById("1").checked=false
      document.getElementById("2").checked=false
      document.getElementById("3").checked=false
      document.getElementById("4").checked=false
      document.getElementById("5").checked=false
    }
    //setDates('')
  }

  return (
    
    
    <section>
      <div className = {permitgrid}>
      <form id="permitform" onSubmit={onSubmit}>
      <br></br>
        <div className={gridcontainer}>
          <span className={border}></span>
          <div className="grid-item">
        <h1>Select Permits</h1>
        <p>Use the search feature to find your desired trailhead. You can search by park or name.</p>
        <p>Maximum of 6 permits per tracker.</p>
        <br></br>

        {user ? (
          <p className="warning">Wildpermits has transitioned to email only notifications. The cost of text messages was not sustainable. Please ensure that <b>{user.email}</b> is your correct email.</p>
          ) : (
            <p></p>
          )}
        </div>
        <MediaQuery maxWidth={600}>
        <span className="bordermobile"></span>
        </MediaQuery>
        <div className="grid-item">
                <MultiSelect
                  className={rmsc}
                  options={options}
                  value={values.permits}
                  onChange={(e) => {
                    if (Array.isArray(e) && e.length > 20){
                      e.length = 20;
                        toast.error("Select a maximum of 20 permits per tracker", {
                          toastId: 'success1',
                      })
                    }
                    else{
                    changeHandler({
                      name: "permits",
                      value: e
                    })
                  }
                }
                  }
                  labelledBy={"Select"}
                  hasSelectAll={false}
                />
                </div>



          </div>
          
          <br></br>
          <div className={gridcontainer}>
          <span className={border}></span>
          <div className = "grid-item">
            <h1>Select Start Dates</h1>
            <p>Use this calendar to select the dates you wish to begin your trip.</p>
            <p>Maximum of 12 dates per tracker.</p>
          </div>
          <MediaQuery maxWidth={600}>
        <span className="bordermobile"></span>
        </MediaQuery>
          <div className="grid-item">
          <Calendar 
            //placeholder ="Pick up to 6 dates"
            className="rmdp-mobile green"
            name="dates"
            multiple
            numberOfMonths={1}
            disableYearPicker
            /*animations={[
              opacity(),
              transition({
                from: 40,
                transition: "all 400ms cubic-bezier(0.335, 0.010, 0.030, 1.360)",
              }),
            ]}*/
            minDate={today}
            maxDate="2024/12/31"
            value={values.dates} 
            format="YYYY-DD-MM"
            //sort
            //format = {format}
            //plugins={[
              //<DatePanel />
             //]}
            onChange={(e) => {
              if (Array.isArray(e) && e.length > 20){
                e.length = 20;
                  toast.error("Select a maximum of 20 dates per tracker", {
                    toastId: 'success1',
                })
              }
              changeHandler({
                name: "dates",
                value: e
              })
            }
            }
          />
          </div>
          </div>
         
          <br></br>
          <div className={gridcontainer}>
          <span className={border}></span>
          <div className = "grid-item">
            <h1>Select Permit #</h1>
            <p>How many permits do you need?</p>
            <p>Selecting fewer permits will increase your chances.</p>
          </div>
          <MediaQuery maxWidth={600}>
          <span className="bordermobile"></span>
          </MediaQuery>
          <div className = "grid-item">
          
          <br></br>



            
          <input className="radInput" name="num" type="radio" value="1" id="1"  onChange={onChange}
           />
           <label className="radlabel" htmlFor="1">1</label>



          <input className="radInput" type="radio" name="num"  value="2" id="2"  onChange={onChange}
          />
          <label className="radlabel" htmlFor="2">2</label>



          <input className="radInput" type="radio" name="num"  value="3" id="3"  onChange={onChange}/>
          <label className="radlabel" htmlFor="3">3</label>


          <input className="radInput" type="radio" name="num"  value="4" id="4"  onChange={onChange}/>
          <label className="radlabel" htmlFor="4">4</label>



          <input className="radInput" type="radio" name="num"  value="5" id="5"  onChange={onChange}/>
          <label className="radlabel" htmlFor="5">5</label>


          </div>
          </div>
          <br></br>
          <div>
            </div>
      </form>
      <MediaQuery minWidth={1000}>
        <div>
        <div class="sidenav">
        <h1>Permits</h1>
        <div class="line-2"></div>
        {values.permits.length > 0 ? (
          <a>{values.permits.map((permit) => <li className = "sidebartext">{getPermit(permit)}</li>)}</a>
          ) : (
            <a className = "sidebartext">None Selected</a>
          )}
          <h1>Dates</h1>
          <div class="line-2"></div>
          {values.dates.length > 0 ? (
          <a>{values.dates.map((day, i) => <li className = "sidebartext">{getDay(day.toDate())}</li>)}</a>
          ) : (
            <a className = "sidebartext">None Selected</a>
            )}
          <h1>Group Size</h1>
          <div class="line-2"></div>
          {values.num != '' ? (
          <a>{getGroup()}</a>
          ) : (
            <a>Not Selected</a>
          )}
        </div>
        </div>
      </MediaQuery>
      </div>
      <button role="button" form="permitform" disabled={getStateButton()} className='btn3' type='submit'>
            Add Permit Tracker
          </button>
          <div className= "center-terms">
          {user ? (
          <p className="terms">By clicking this button, you consent to recieve email messages 
            regarding this request to {user.email}. This information will not be used for any 
            promotional or marketing purposes.
          </p>
          ) : (
            <p></p>
          )}
          </div>
    </section>
  )
}

export default GoalForm
