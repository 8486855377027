import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import GoalForm from '../components/GoalForm'
import GoalItem from '../components/GoalItem'
import Spinner from '../components/Spinner'
import { getGoals, reset } from '../features/goals/goalSlice'
import { logout} from '../features/auth/authSlice'
import { useMediaQuery } from 'react-responsive'
import MediaQuery from 'react-responsive'
import axios from "axios";
import {Helmet} from 'react-helmet'


import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import AccordionSummary from "@material-ui/core/AccordionSummary";

function Dashboard() {
  const isMobile = useMediaQuery({ query: '(max-width: 1000px)' });
  const flexcontainer = isMobile ? 'flexcontainer2-mobile' : 'flexcontainer2';
  const goalphoto = isMobile ? 'goalphoto-mobile' : 'goalphoto';
  const backgroundDiv = isMobile ? 'backgroundDiv2-mobile' : 'backgroundDiv2';
  const overlay = isMobile ? 'overlayPermit-mobile' : 'overlayPermit';
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { user } = useSelector((state) => state.auth)
  const { goals, isLoading, isError, message } = useSelector(
    (state) => state.goals
  )
  

  useEffect(() => {
    if (isError) {
      dispatch(logout())
      navigate('/login')
      return () => {
        dispatch(reset())
      }
    }

    if (!user) {
      navigate('/login')
      return () => {
        dispatch(reset())
      }
    }

    dispatch(getGoals())

    return () => {
      dispatch(reset())
    }
  }, [user, navigate, isError, message, dispatch])


  return (
    <>
    <Helmet>
    <title>Permit Tracker | WildPermits</title>
    <meta name='Permit Tracker' content='Backpacking permit tracker page for WildPermits.' />
    </Helmet>
      <div className={backgroundDiv}>
      <div className={goalphoto}>
        <div className={overlay}></div>
        <p>Permit Dashboard</p>
      </div>

      <div style={{}}>
      <Accordion style={{ width: "100%" }}>
        <AccordionSummary
          className="accordian" 
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
        >
          <Typography
            style={{
              fontWeight: 10,
            }}
          >
            Changelog and Tips
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <h2 className = "alignleft">Changelog</h2>
            <li>
              3/16/2023: The program now correctly supports lottery dates in Yosemite. It will no longer text you if the date is not reservable yet.
            </li>
            <li>
              3/17/2023: Added Weather and AQI forecast for the zones supported by the program.
            </li>
            <h2 className="alignleft">Tips</h2>
            <li>The Enchantments releases a small amount of permits for the following week every sunday once the lottery period has ended. You can secure a last minute permit by booking immediately when you get your text on Sunday.</li>
            <li>Unclaimed Yosemite lottery permits release 12 days after the lottery opens at 9 am. For example, if a lottery opened on March 5, the unclaimed permits would release on March 17 9AM PDT</li>
            <a href="https://www.nps.gov/yose/planyourvisit/wildpermitdates.htm">Recreation.gov Release Schedule link</a>
            <p>If you want to try and grab one of these Yosemite permits at 9 am, I suggest being there at the recreation.gov page refreshing at that time. My program will remind you at 9 am, but you are competing with a lot of people who are already at the page.</p>
            <li>Whitney and Lost Coast permits are highly coveted but are some of the easiest permits to secure with this program.</li>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>

      <GoalForm />
      </div>
      <section className='content'>
        {goals.length > 0 ? (
          <>
          <MediaQuery maxWidth={600}>
          <h3>Delete a tracker by clicking the card.</h3>
          </MediaQuery>
          <MediaQuery minWidth={601}>
          <h3>Delete a tracker by hovering over it.</h3>
          </MediaQuery>
          <div className={flexcontainer}>
            {goals.slice(0).reverse().map((goal) => (
              <GoalItem key={goal._id} goal={goal} />
            ))}
          </div>
          </>
        ) : (
          <>
          <h3>You have not created any permit trackers</h3>
          </>
        )}
      </section>
    </>
  )
}

export default Dashboard
