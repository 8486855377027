import { useState, useEffect } from 'react'
import { FaSignInAlt } from 'react-icons/fa'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { login, reset } from '../features/auth/authSlice'
import Spinner from '../components/Spinner'
import yosemite from '../ben-petchel-uY50b2dqKUQ-unsplash.jpg'
import inyo from '../inyo.jpg'
import lostcoast from '../Lostcoast.jpg'
import seki from '../seki.jpg'
import whitney from '../whitney.jpg'
import desolation from '../desolation.jpg'
import { useMediaQuery } from 'react-responsive'
import MediaQuery from 'react-responsive'
import enchantments from '../enchantments.jpg'
import { getStats, resetStats } from '../features/goals/statsSlice'
import axios from "axios";
import useScript from './usescript';
import {Helmet} from 'react-helmet'
//import background from "./20210815_104254.jpg";



function WelcomePage() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.auth)
  const isMobile = useMediaQuery({ query: '(max-width: 1000px)' });
  const gridcontainer = isMobile ? 'grid-container-howitworks-mobile' : 'grid-container-howitworks';
  const flexcontainer = isMobile ? 'flexcontainer-mobile' : 'flexcontainer';
  const mainphoto = isMobile ? 'mainphoto-mobile' : 'mainphoto';
  const center = isMobile ? 'center-mobile' : 'center';
  const btn = isMobile ? 'btn-mobile' : 'btn';
  const card = isMobile ? 'card-mobile' : 'card';
  const overlay = isMobile ? 'overlay-mobile' : 'overlay';
  const texts = isMobile ? 'totaltexts-mobile' : 'totaltexts';
  const invistexts = isMobile ? 'invistext-mobile' : 'invistext';
  const onRegister = () => {
    navigate('/register')
  }
  const onLogin = () => {
    navigate('/login')
  }
  const onDash = () => {
    navigate('/dashboard')
  }
  
  const { statsList , isLoading, isError, message } = useSelector(
    (state) => state.stats
  )
  
  useEffect(() => {
    dispatch(getStats())

    return () => {
      dispatch(resetStats())
    }
  }, [dispatch])
  console.log(statsList)


  //useScript('foobar.jsx');
  
  //const [sentenceData, setSentenceData] = useState({})

  /*useEffect(() => {

    // check fields
    // check email
    try {
      axios.get("/api/stats/").then(resp => {
        setSentenceData(resp.data[0].totaltext)
      });
    } catch (err) {
      toast(err.response.data.msg, {
      });
    }
  }, [])*/

  /*useEffect(() => {
    const fetch = async () => {
      const response = await axios.get("/api/stats/");
      const data = await response.json();
      setSentenceData(data);
    }
    
    fetch();
  }, []);*/



  return (
    <>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0" />
    <Helmet>
    <title>WildPermits | Get Notified of Cancelled Backpacking Permits</title>
    <meta name='Get Notified of Cancelled Backpacking Permits.' content='Get Notified of Cancelled Backpacking Permits.' />
    </Helmet>
    <div className={mainphoto}>
      <p className="titleanim">Get Notified of Cancelled Permits</p>
      {statsList.length > 0 ? (
      <>
      <a className = {texts}>{statsList[0].totaltext.toLocaleString("en-US")} Email Notifications Sent to 1,000+ Users</a>
      </>
      ) : (
      <>
      <a className = {invistexts}>.</a>
      </>
      )}
      {user ? (
          <>
            <div class="container">
        <div class="centerwelcome">
          <button class={btn} onClick={onDash}>
            <svg width="180px" height="60px" viewBox="0 0 180 60" class="border">
              <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
              <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
            </svg>
            <span>Permit Tracker</span>
          </button>
        </div>
      </div>
            </>
        ) : (
          <>
            <div class="container">
        <div class="centerwelcome">
          <button class={btn} onClick={onRegister}>
            <svg width="180px" height="60px" viewBox="0 0 180 60" class="border">
              <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
              <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
            </svg>
            <span>Register</span>
          </button>
        </div>
        <MediaQuery maxWidth={600}>
        <div class="centerlogin">
          <button class="btnlog" onClick={onLogin}>
              <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
              <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
            <span>Login</span>
          </button>
        </div>
          </MediaQuery>
      </div>
          </>
        )}
        <div className={overlay}>
      </div>
    </div>
    <h5>How it Works</h5>
    <div className={gridcontainer}>
      <div className="grid-item-howitworks">
        <h4>Step 1:</h4>
        <p>Register an account with your email. This is and will always be a free service to help everyone access our public land.</p>
        <p className="data">Your data will always remain private.</p>
      </div>
      <MediaQuery maxWidth={600}>
          <span className="bordermobile"></span>
          </MediaQuery>
      <div className="grid-item-howitworks">
        <h4>Step 2:</h4>
        <p>Go to your dashboard and create a permit tracker with your desired trailheads, dates, and party size.</p>
        </div>
        <MediaQuery maxWidth={600}>
          <span className="bordermobile"></span>
          </MediaQuery>
      <div className="grid-item-howitworks">
        <h4>Step 3:</h4>
        <p>Recieve email alerts from WildPermits, which refreshes the permit database every minute using Recreation.gov API. Never miss a cancelled permit again.</p>
      </div>
    </div>

    <h4>Supported Parks</h4>

    <div className={flexcontainer}>
          <div class = {card}>
            <img src={yosemite} alt=""></img>
            <div class="card-content">
              <h2>
                Yosemite National Park
              </h2>
              <p>
                
              </p>
              <a href="https://www.nps.gov/yose/index.htm" class="button">
                Find out more 
              </a>
            </div>
          </div>
          <div class = {card}>
            <img src={seki} alt=""></img>
            <div class="card-content">
              <h2>
                Sequoia and Kings Canyon
              </h2>
              <p>
                
              </p>
              <a href="https://www.nps.gov/seki/index.htm" class="button">
                Find out more 
              </a>
            </div>
          </div>
          <div class = {card}>
            <img src={lostcoast} alt=""></img>
            <div class="card-content">
              <h2>
                King's Range
              </h2>
              <p>
                
              </p>
              <a href="https://www.blm.gov/visit/lost-coast-trail" class="button">
                Find out more 
              </a>
            </div>
          </div>
          <div class = {card}>
            <img src={whitney} alt=""></img>
            <div class="card-content">
              <h2>
                Mount Whitney
              </h2>
              <p>
                
              </p>
              <a href="https://www.nps.gov/seki/planyourvisit/whitney.htm" class="button">
                Find out more 
              </a>
            </div>
          </div>
          <div class = {card}>
            <img src={desolation} alt=""></img>
            <div class="card-content">
              <h2>
                Desolation Wilderness
              </h2>
              <p>
                
              </p>
              <a href="https://www.fs.usda.gov/detail/eldorado/specialplaces/?cid=fsbdev7_019062" class="button">
                Find out more 
              </a>
            </div>
          </div>
          <div class = {card}>
            <img src={inyo} alt=""></img>
            <div class="card-content">
              <h2>
                Inyo National Forest
              </h2>
              <p>
                
              </p>
              <a href="https://www.fs.usda.gov/inyo" class="button">
                Find out more 
              </a>
            </div>
          </div>
          <div class = {card}>
            <img src={enchantments} alt=""></img>
            <div class="card-content">
              <h2>
                Alpine Lakes Wilderness
              </h2>
              <p>
                
              </p>
              <a href="https://www.fs.usda.gov/inyo" class="button">
                Find out more 
              </a>
            </div>
          </div>
    </div>
    <div className="contentmid">
    <div class="testimonial-slider">
  <ul class="slider">
    <li>
      <div class="testimonial-slider-content">
        <q>Just got a permit for the Core Enchantments. Thank you so much.</q>
        <p class="source">- Lydia G.</p>
      </div>
    </li>
    <li>
      <div class="testimonial-slider-content">
        <q>Hi! I was able to book our first overnight backpacking trip in the Sierras in July. I'm super excited, and super grateful for your tracker app, since it would have been very unlikely for us to be able to go without it. Thank you so much!</q>
        <p class="source">- Reddit User</p>
      </div>
    </li>
    <li>
      <div class="testimonial-slider-content">
        <q>Thanks for this. I ended up being able to do Whitney last year thanks to your service!</q>
        <p class="source">- Reddit User</p>
      </div>
    </li>
    <li>
      <div class="testimonial-slider-content">
        <q>My dad lost the Enchantments lottery every year, i'm glad I finally get to take him. Thanks!</q>
        <p class="source">- Fatima P.</p>
      </div>
    </li>
    <li>
      <div class="testimonial-slider-content">
        <q>Thank you for making this free, I paid so much money for Yosemite lotteries and got nothing. I was able to get a JMT spot with your website.</q>
        <p class="source">- Alan Z.</p>
      </div>
    </li>
    <li>
      <div class="testimonial-slider-content">
        <q>Just wanted to email you to let you know I love this website, I've already gotten 3 trips for this summer.</q>
        <p class="source">- Anonymous</p>
      </div>
    </li>
    <li>
      <div class="testimonial-slider-content">
        <q>Thanks for adding Whitney last year, I just got a text for it!</q>
        <p class="source">- Jeffy</p>
      </div>
    </li>
    <li>
      <div class="testimonial-slider-content">
        <q>Thanks for the recommendations Michael, we were able to get the High Trail permits for September.</q>
        <p class="source">- Tamsyn P.</p>
      </div>
    </li>
  </ul>
  </div>
  </div>

    <h1 className="normalHeader"></h1>






    </>
  )
}

export default WelcomePage
