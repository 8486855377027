import { configureStore } from '@reduxjs/toolkit'
import authReducer from '../features/auth/authSlice' //now bring in authreducer after creating
import goalReducer from '../features/goals/goalSlice'
import weatherReducer from '../features/goals/weatherSlice'
import statsReducer from '../features/goals/statsSlice'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    goals: goalReducer,
    weathers: weatherReducer,
    stats: statsReducer,
  },
})
