import { useDispatch } from 'react-redux'
import { deleteGoal } from '../features/goals/goalSlice'
import React from 'react';
import { useState } from 'react'

function GoalItem({ goal }) {
  const dispatch = useDispatch()
  
  const [isFadingOut, setIsFadingOut] = useState(false);
    
    const fadeOut = (cb) => {
        setIsFadingOut(true);

    };
    const handleRemoveItem = () => {
        dispatch(deleteGoal(goal._id))
        setIsFadingOut(false);
    };

  return (
    <div>
      <div className={isFadingOut ? 'flip-card-container-fadeout' : 'flip-card-container'}>
  <div className="flip-card">

    <div className="card-front">
      <figure>
        <div className="img-bg"></div>
        <img src="https://images.unsplash.com/photo-1486162928267-e6274cb3106f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60" alt="Brohm Lake"></img>    
        <figcaption>Group Size: {goal.numpermit}</figcaption>
      </figure>

      <ul className="cardul">
        <li className="cardli">Dates</li>
        {goal.values.map(item => {
          return <a key={item}>{item}</a>; //need unique key
        })}
        <li className="cardli">Trails</li>
        {goal.permit.map(item => {
          return <a key={item}>{item}</a>; //need unique key
        })}
      </ul>
    </div>

    <div className="card-back">
      <figure>
        <div className="img-bg"></div>
        <img src="https://images.unsplash.com/photo-1486162928267-e6274cb3106f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60" alt="Brohm Lake"></img>
      </figure>

      <button style={{ color: "red" }} onClick={() => fadeOut(setTimeout(() => handleRemoveItem(), 1000))} className='close'>
      Delete
      </button>

      <div className="design-container">
        <span className="design design--1"></span>
        <span className="design design--2"></span>
        <span className="design design--3"></span>
        <span className="design design--4"></span>
        <span className="design design--5"></span>
        <span className="design design--6"></span>
        <span className="design design--7"></span>
        <span className="design design--8"></span>
      </div>
    </div>

  </div>
</div>
</div>

  )
}

export default GoalItem
