import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import GoalForm from '../components/GoalForm'
import WeatherWidget from '../components/WeatherWidget'
import { getWeather, reset } from '../features/goals/weatherSlice'
import { logout} from '../features/auth/authSlice'
import { useMediaQuery } from 'react-responsive'
import MediaQuery from 'react-responsive'
import { useState } from 'react'
import {Helmet} from 'react-helmet'
import { LineChart, ResponsiveContainer, Line, CartesianGrid, XAxis, YAxis, Tooltip, Label } from 'recharts';


import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import AccordionSummary from "@material-ui/core/AccordionSummary";


function Weather() {
  const isMobile = useMediaQuery({ query: '(max-width: 1000px)' });
  const flexcontainer = isMobile ? 'flexcontainer2-mobile' : 'flexcontainer2';
  const content = isMobile ? 'weathercontents' : 'content';
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { user } = useSelector((state) => state.auth)
  const { weatherList , isLoading, isError, message } = useSelector(
    (state) => state.weathers
  )
  const [values, setAllValues] = useState({
    zone: "",
  });
  const {
    zone,
  } = values

  const onChange = (e) => {
    values.zone = e.target
    console.log(e.value)
    setAllValues( prevValues => {
      return { ...prevValues,["zone"]: e.target.value}
      })

  }

  useEffect(() => {
    console.log(values)
    dispatch(getWeather({values}))

    return () => {
      dispatch(reset())
    }
  }, [dispatch, values])


  return (
    <>
    <Helmet>
    <title>Forecast | WildPermits</title>
    <meta name='Forecast' content='Forecast page for WildPermits.' />
    </Helmet>
    <div className="flexcontainerweather2">
    <h4>Weather and Air Quality forecast</h4>
    <h3>I have included several elevations so you can get a better idea of how weather will change as you ascend from the trailhead</h3>
      <div className = "flexweathermain">
        <div className = "flexcenter">
      <input className="radInput2" name="zone" type="radio" value="yosemite" id="5"  onChange={onChange}
           />
           <label className="radlabel2" htmlFor="5">Yosemite</label>
          <input className="radInput2" type="radio" name="zone"  value="inyo" id="4"  onChange={onChange}
          />
          <label className="radlabel2" htmlFor="4">Inyo</label>

          <input className="radInput2" type="radio" name="zone"  value="sequoia" id="3"  onChange={onChange}/>
          <label className="radlabel2" htmlFor="3">Seki</label>

          <input className="radInput2" type="radio" name="zone"  value="whitney" id="2"  onChange={onChange}/>
          <label className="radlabel2" htmlFor="2">Whitney</label>

          <input className="radInput2" type="radio" name="zone"  value="lostcoast" id="1"  onChange={onChange}/>
          <label className="radlabel2" htmlFor="1">Lost Coast</label>
          </div>
      </div>
      </div>
      <section className={content}>
        {weatherList.length < 5 && weatherList.length > 0 ? (
          <>
          <h3> AQI (Air Quality) Chart</h3>
          <div className="flexweathermain">
          <ResponsiveContainer width={"100%"} height={300} min-width={300}>
          <LineChart 
          data={weatherList[0].airquality}
          margin={{
            top: 10,
            bottom: 20,
            left: -19,
          }}
          >
            <Line type="monotone" dataKey="air" strokeWidth="2" stroke="#8884d8" dot={false} />
            <CartesianGrid strokeDasharray="4 4" />
            <XAxis xAxisId="0" dataKey="time" tick={false} axisLine={false}>
            </XAxis>
            <XAxis  xAxisId="1" dataKey="day" axisLine={false} allowDuplicatedCategory={false} />
            <YAxis>
            </YAxis>
            <Tooltip />
        </LineChart>
        </ResponsiveContainer>
        </div>
          <div>
            {weatherList.map((weather) => (
              <WeatherWidget key={weather._id} weather={weather} />
            ))}
          </div>
          </>
        ) : (
          <>
          
          </>
        )}
      </section>
    </>
  )
}

export default Weather
