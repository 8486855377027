import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import authService from './authService'

// Get user from localStorage
const user = JSON.parse(localStorage.getItem('user')) //gets token from local storage. localstorage can only have strings so you parse

const initialState = {
  user: user ? user : null,
  isError: false, //if we get an error back, we can make that true or show an error message
  isSuccess: false,
  isLoading: false,
  message: '',
}

// Register user
export const register = createAsyncThunk(
  'auth/register',
  async (user, thunkAPI) => { //user gets passed in from register component/page
    try {
      return await authService.register(user) //this is the payload that is coming back from register. this is the action
    } catch (error) {
      const message =
        (error.response &&
          error.response.data && //check if any errors exist
          error.response.data.message) ||
        error.message || //check if just error.message exists
        error.toString() //or just error
      return thunkAPI.rejectWithValue(message) //will reject and send error
    }
  }
)

// Login user
export const login = createAsyncThunk('auth/login', async (user, thunkAPI) => {
  try {
    return await authService.login(user)
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const logout = createAsyncThunk('auth/logout', async () => {
  await authService.logout() //destroys token
})

export const authSlice = createSlice({ //gets passed an object
  name: 'auth',
  initialState, //pass in initial state
  reducers: { //these are not async
    reset: (state) => { //helps reset state to default values
      state.isLoading = false //set to initial values
      state.isSuccess = false
      state.isError = false
      state.message = ''
    },
  },
  extraReducers: (builder) => { //these are async. //extrarducers handles all the cases. For all rejected, pending, success
    builder
      .addCase(register.pending, (state) => {
        state.isLoading = true
      })
      .addCase(register.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.user = action.payload
      })
      .addCase(register.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.user = null
      })
      .addCase(login.pending, (state) => {
        state.isLoading = true
      })
      .addCase(login.fulfilled, (state, action) => { //get user token, so we have action passed in.
        state.isLoading = false //set state back to false
        state.isSuccess = true
        state.user = action.payload //user is now the action.payload.
      })
      .addCase(login.rejected, (state, action) => { //we called reject with value, so we need the action from the failed login
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.user = null
      })
      .addCase(logout.fulfilled, (state) => {
        state.user = null
      })
  },
})

export const { reset } = authSlice.actions //export reset action
export default authSlice.reducer //export reducers.
