import React, { useEffect } from "react";

function DesolationRedirect() {

  useEffect(() => {
    window.location.href = "https://www.recreation.gov/permits/233261/registration/detailed-availability";
  }, []);

  return (
    <div>
      <h2>Redirecting to Recreation.gov</h2>
    </div>
  );
}

export default DesolationRedirect;