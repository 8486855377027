import statsService from './statsService'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

const initialState = {
    statsList: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
  }

export const getStats = createAsyncThunk(
'stats/getAll',
async (thunkAPI) => {
    try {
    return await statsService.getStats()
    } catch (error) {
    const message =
        (error.response &&
        error.response.data &&
        error.response.data.message) ||
        error.message ||
        error.toString()
    return thunkAPI.rejectWithValue(message)
    }
}
)

export const statsSlice = createSlice({
    name: 'stats',
    initialState,
    reducers: {
      resetStats: (state) => initialState,
    },
    extraReducers: (builder) => {
      builder
        .addCase(getStats.pending, (state) => {
          state.isLoading = true
        })
        .addCase(getStats.fulfilled, (state, action) => {
          state.isLoading = false
          state.isSuccess = true
          state.statsList = action.payload
        })
        .addCase(getStats.rejected, (state, action) => {
          state.isLoading = false
          state.isError = true
          state.statsList = action.payload
        })
    },
  })
  
  export const { resetStats } = statsSlice.actions
  export default statsSlice.reducer