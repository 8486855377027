import axios from 'axios' //the service is for making http request and sending any local storage back. similar to requesting from postman

const API_URL = '/api/users/'

// Register user
const register = async (userData) => {
  const response = await axios.post(API_URL, userData) //send a response and put it in this variable. api_url = localhost/user
  //axios puts the object into "data"
  if (response.data) { //take in user data
    localStorage.setItem('user', JSON.stringify(response.data)) //stringify to localstorage. includes token
  }

  return response.data
}

// Login user
const login = async (userData) => {
  const response = await axios.post(API_URL + 'login', userData)

  if (response.data) {
    localStorage.setItem('user', JSON.stringify(response.data))
  }

  return response.data
}

// Logout user
const logout = () => {
  localStorage.removeItem('user') //destroys token from localstorage
}

const authService = {
  register,
  logout,
  login,
}

export default authService
