import { FaSignInAlt, FaSignOutAlt, FaUser } from 'react-icons/fa' //icons
import { Link, useNavigate } from 'react-router-dom' //links for pages
import { useSelector, useDispatch } from 'react-redux'
import { logout, reset } from '../features/auth/authSlice'

function Footer() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.auth)

  const onLogout = () => {
    dispatch(logout())
    dispatch(reset())
    navigate('/')
  }
  //add icons and stuff. if user ? display logout :else login register
  return (
    <footer className='footer'>
      <div className='logo'>
        <p className="recgov">Powered by Recreation.gov API - This app is not endorsed by Recreation.gov</p>
        <p>Created by Michael Feger</p>
      </div>
    </footer>
  )
}

export default Footer