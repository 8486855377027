import axios from 'axios'

const API_URL = '/api/conditions/'


const getWeather = async (zone) => {
  const search = zone.values.zone
  const response = await axios.get(API_URL, {
    params: {
      check: search
    }})
  return response.data
}

const weatherService = {
  getWeather,
}

export default weatherService
