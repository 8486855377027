import { useState, useEffect } from 'react'
import { FaSignInAlt } from 'react-icons/fa'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { login, reset } from '../features/auth/authSlice'
import Spinner from '../components/Spinner'
import {Helmet} from 'react-helmet'
//import background from "./20210815_104254.jpg";
import logo from '../yosemitetitle.jpg';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css"
//import Lightbox from 'react-image-lightbox';
//import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
//import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
//import { Carousel } from 'react-responsive-carousel';


function Yosemite() {
    const images3 = [
        '//placekitten.com/1500/500',
        '//placekitten.com/4000/3000',
        '//placekitten.com/800/1200',
        '//placekitten.com/1500/1500',
      ];


    const images = [
        {
          original: 'https://i.imgur.com/kbuIIh2.jpg',
          thumbnail: 'https://i.imgur.com/kbuIIh2t.jpg',
          originalTitle: 'Hello',
        },
        {
          original: 'https://i.imgur.com/0isR99Y.jpg',
          thumbnail: 'https://i.imgur.com/0isR99Yt.jpg',
        },
        {
          original: 'https://i.imgur.com/Y1y5BK8.jpg',
        },
        {
          original: 'https://i.imgur.com/w2tbbiM.jpg',
        },
      ];

      var images2 = [
        {
          src: "https://images.unsplash.com/photo-1577279549270-b9e297533cdd?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1534&q=80",
          desc: 'Person wearing shoes',
          sub: 'Gift Habeshaw'
        },
        {
          src: "https://images.unsplash.com/photo-1577277625082-36df4915ebeb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80",
          desc: 'Blonde woman wearing sunglasses smiling at the camera ',
          sub: 'Dmitriy Frantsev'
        }
      ]


  return (
    <>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0" />
    <link href="https://fonts.googleapis.com/icon?family=Material+Icons"
      rel="stylesheet"></link>
    <Helmet>
    <title>Trails | WildPermits</title>
    <meta name='Trails' content='Trails page for WildPermits.' />
    </Helmet>
    <section className="backgroundDiv3">
    {/*<div className="overlaytrails"></div>
    <div className="cloudsrest">
        <p>Cloud's Rest to Yosemite Valley</p>
  </div>*/}
  <div className="gallery-wrapper">
    <div className="image-gallery-wrapper">
        <h1 className="pspecial">Cloud's Rest to Yosemite Valley</h1>
        <div className="overlaytrails"></div>
        <ImageGallery showThumbnails={false} showBullets={true} lazyLoad={true} showPlayButton={false} items={images}>
        </ImageGallery>
    </div>
    </div>

    
    <div className="maingrid">
    <div>
    <h2 className="infotrails">Info</h2>
    <span className="linetrails"></span>
    <div className="trailgrid">
        <div>
            <p> <span class="material-icons" style={{color:"green"}}>
                    hiking
                    </span>
            14.86 Miles(Point to Point)</p>
        </div>
        <div>
            <p> <span class="material-icons" style={{color:"green"}}>
                trending_up
            </span>

                2603 Feet
                </p>
        </div>
        <div>
            <p> <span class="material-icons" style={{color:"red"}}>
                trending_down
            </span>

                -6732 Feet
                </p>
        </div>
        <div>
            <p> <span class="material-icons" style={{color:"green"}}>
                punch_clock
                </span>
            1-2 Nights</p>
        </div>
        <div>
            <p> <span class="material-icons" style={{color:"orange"}}>
            flag
            </span>
            Start: Sunrise Lakes Trailhead</p>
        </div>
        <div>
            <p> <span class="material-icons" style={{color:"orange"}}>
            flag
            </span>
            End: Happy Isles Trailhead</p>
        </div>
    </div>
    <h2 className="infotrails">Description</h2>
    <p>This is my favorite trip in Yosemite National Park. You begin at the Sunrise Lakes trailhead
        and ascend Cloud's Rest with the option of camping at Sunrise Lakes or below the final ascent to
        Cloud's Rest. The next day, you go down past Half Dome to camp in Little Yosemite Valley. The 
        final day takes you down through the mist Trail with views of Nevada and Vernal falls.
         This permit allows the holder to optionally add a Half Dome permit.
    </p>
    <h2 className="infotrails">Camp Locations</h2>
    <p className="fixtext">
      <img className="thumbnailcamp" src="https://i.imgur.com/w2tbbiMt.jpg"></img>
      Sunrise Lakes
    </p>
    <div>
    <p className="fixtext">
      <img className="thumbnailcamp" src="https://i.imgur.com/Y1y5BK8t.jpg"></img>
      Little Yosemite Valley
    </p>
    </div>
    <h2 className="infotrails">Logistics</h2>
    <a href="https://yarts.com/routes/mammoth-lakes-hwy-120e-395/">Yarts HWY 120 Bus</a> <a> will take you from 
    the ending trailhead to the starting trailhead on day 1. You can do this backwards on the last day 
    but due to bus schedules, it is easier to do day 1.</a>
    </div>
    <div className="iframeline">
    <iframe src="https://caltopo.com/m/3UQ01" maptype="terrain" width="100%" height="100%"></iframe>
    </div>
    

    </div>

    </section>
    
    </>
  )
}

export default Yosemite
