
import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux' //useselector is used to select something from state. such as isuser isloading. usedispatch allows us to use functions
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { FaUser } from 'react-icons/fa'
import { register, reset } from '../features/auth/authSlice' //used with dispatch
import Spinner from '../components/Spinner'
import validator from 'validator' 
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {Helmet} from 'react-helmet'


function Register() {
  const [formData, setFormData] = useState({ //create a state for register form
    name: '',
    email: '',
    password: '',
    password2: '',
  })
  const [value, setValue] = useState()

  const { name, email, password, password2 } = formData

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  )

  useEffect(() => { //this wiill toast any errors
    if (isError) {
      toast.error(message)
    }

    if (isSuccess || user) {
      navigate('/dashboard')
    }

    dispatch(reset()) //reset after
  }, [user, isError, isSuccess, message, navigate, dispatch])

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value, //shows stuff as you type it
    }))
  }

  const onSubmit = (e) => {
    e.preventDefault()
    var phone = value
    var texton = 0
    console.log(phone)
    phone = "1"
    console.log(phone)
    /*const validatePhoneNumber = (phonenum) => { //enable for phone later
      const isValidPhoneNumber = validator.isMobilePhone(phonenum)
      return (isValidPhoneNumber)
     }*/

    //phone.replace('+', ''); //enable phone later
    //console.log(phone)

    if (password !== password2) {
      toast.error('Passwords do not match', {
        toastId: 'success1',
    }) //shows a popup on screen, wrapped around app
    /*}
    else if (!validatePhoneNumber(phone)) {
      toast.error('Enter a valid phone number', {
        toastId: 'success1',
    })*/
    } else {
      const userData = {
        name,
        email,
        password,
        phone,
        texton,
      }
      userData.email = userData.email.toLowerCase()

      dispatch(register(userData)) //dispatch to database
    }
  }


  return (
    <>
    <Helmet>
    <title>Register | WildPermits</title>
    <meta name='Register Page' content='Register page for WildPermits.' />
    </Helmet>
    <div className="formcard">
      <div className="formcard-image">
      <div className="overlaylogin"></div>
      <h2 class="card-heading">
				Get started
				<small>Let us create your account</small>
			</h2>
      </div>
      <section className='heading'>
      </section>

      <section className='form'>
        <br></br>
        <form onSubmit={onSubmit}>
          <div className='inputreg'>
            <input
              type='text'
              className='input-fields'
              id='name'
              name='name'
              value={name}
              onChange={onChange}
              required
            />
            <label className="input-labels">Username</label>
          </div>
          <div className='inputreg'>
            <input
              type='text'
              className='input-fields'
              id='email'
              name='email'
              value={email.toLowerCase()}
              onChange={onChange}
              required
            />
            <label className="input-labels">Email</label>
          </div>
          <div className='inputreg'>
            <input
              type='password'
              className='input-fields'
              id='password'
              name='password'
              value={password}
              onChange={onChange}
              required
            />
            <label className="input-labels">Password</label>
          </div>
          <div className='inputreg'>
            <input
              type='password'
              className='input-fields'
              id='password2'
              name='password2'
              value={password2}
              onChange={onChange}
              required
            />
            <label className="input-labels">Confirm Password</label>
          </div>
          <div className='form-group'>
            <button className="btn2" type='submit'>
              Submit
            </button>
          </div>
        </form>
      </section>
      </div>
    </>
  )
}

export default Register
