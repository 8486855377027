import { FaSignInAlt, FaSignOutAlt, FaUser } from 'react-icons/fa' //icons
import { Link, useNavigate } from 'react-router-dom' //links for pages
import { useSelector, useDispatch } from 'react-redux'
import { logout, reset } from '../features/auth/authSlice'
import logo from './logofinish.png';
import { useMediaQuery } from 'react-responsive'
import MediaQuery from 'react-responsive'
import { NavLink } from 'react-router-dom';


function Header() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.auth)

  function  closeSideMenu() {
    const sideMenuToggle = document.querySelector('#menucheck');
    sideMenuToggle.click();
  }

  const isMobile = useMediaQuery({ query: '(max-width: 1000px)' });
  const header = isMobile ? 'header-mobile' : 'header';
  const headerright = isMobile ? 'header-right-mobile' : 'header-right';

  const onLogout = () => {
    dispatch(logout())
    dispatch(reset())
    navigate('/')
    if(isMobile)
      closeSideMenu()
  }
  const onLogin = () => {
    navigate('/login')
    if(isMobile)
      closeSideMenu()
  }
  const onRegister = () => {
    window.scrollTo(0, 0);
    navigate('/register')
    if(isMobile)
      closeSideMenu()
  }
  const dashboard = () => {
    navigate('/dashboard')
    if(isMobile)
      closeSideMenu()
  }
  const conditions = () => {
    navigate('/conditions')
    if(isMobile)
      closeSideMenu()
  }
  const home = () => {
    navigate('/')
    if(isMobile)
      closeSideMenu()
  }
  const contact = () => {
    navigate('/contact')
    if(isMobile)
      closeSideMenu()
  }
  const about = () => {
    navigate('/about')
    if(isMobile)
      closeSideMenu()
  }
  //add icons and stuff. if user ? display logout :else login register
  return (
    <>
    <MediaQuery maxWidth={600}>
    <div className="flexnav">
    <nav role="navigation">
            <div id="menuToggle">
              <input id="menucheck" type="checkbox" />
                <span></span>
                <span></span>
                <span></span>
            <ul id="menu">
            <a className="nava" onClick={home}>
                Home
            </a>
            <a className="nava" onClick={about}>
                About
            </a>
            <a className="nava" href="https://www.paypal.com/donate/?business=BEF3K7XJ2PLW6&no_recurring=0&item_name=I+appreciate+any+help+with+this+project.+This+app+will+always+be+free.&currency_code=USD">
                Donate
              </a>
            <a className="nava" onClick={contact}>Contact</a>
        {user ? (
          <>
            <a className="nava" onClick={conditions}>
              Forecast
            </a>
            <a className="nava" onClick={dashboard}>
            Permit Tracker
            </a>
            <a className="nava" onClick={onLogout}>
              Logout
            </a> 
            </>
        ) : (
          <>
            <a className="nava" onClick={onLogin}>
                Login
            </a>
            <a className="nava" onClick={onRegister}>
                Register
            </a>
          </>
        )}
            </ul>
           </div>
          </nav>
          <h6>WildPermits</h6>
          </div>
    </MediaQuery>
    <MediaQuery minWidth={601}>
    <header className={header}>
      <a onClick={home} class="logo">
        WildPermits
        </a>
          
        <div class={headerright}>
        <form action="https://www.paypal.com/donate" method="post" target="_top">
            <input type="hidden" name="business" value="BEF3K7XJ2PLW6" />
            <input type="hidden" name="no_recurring" value="0" />
            <input type="hidden" name="item_name" value="Thank you for supporting this project. This service will always be free, please do not feel pressured to donate." />
            <input type="hidden" name="currency_code" value="USD" />
            <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
            <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
            </form>
            <NavLink to='/' activeClassName="active">Home</NavLink>
            <NavLink to='/about' activeClassName="active">About</NavLink>
            <NavLink to='/conditions' activeClassName="active">Forecast</NavLink>
            <NavLink to='/contact' activeClassName="active">Contact</NavLink>
        {user ? (
          <>
            <NavLink to='/dashboard' activeClassName="active">Permit Tracker</NavLink>
            <a onClick={onLogout}>
              Logout
            </a> 
            </>
        ) : (
          <>
            <NavLink to='/login' activeClassName="active">Login</NavLink>
            <NavLink to='/register' activeClassName="active">Register
            </NavLink>
          </>
        )}
        </div>
    

    </header>
    </MediaQuery>
    </>
  )
}

export default Header
