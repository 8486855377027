import { useMediaQuery } from 'react-responsive'
import MediaQuery from 'react-responsive'
import {BsPhoneVibrate} from 'react-icons/bs'
import {FaTwitter} from "react-icons/fa"
import {IconContext} from "react-icons"
import { FaSignInAlt } from 'react-icons/fa'
import EdgesensorHighIcon from '@mui/icons-material/EdgesensorHigh';
import { useSelector, useDispatch } from 'react-redux'
import { getStats, resetStats } from '../features/goals/statsSlice'
import { useState, useEffect } from 'react'
import {Helmet} from 'react-helmet'
//import background from "./20210815_104254.jpg";




function About() {
  const isMobile = useMediaQuery({ query: '(max-width: 1000px)' });
  const container = isMobile ? 'aboutwrapper-mobile' : 'aboutwrapper';
  const howitworks = isMobile ? 'aboutgriditem2-mobile' : 'aboutgriditem2';
  const aboutgrid = isMobile ? 'aboutgrid-mobile' : 'aboutgrid';  
  const dispatch = useDispatch()

  const { statsList , isLoading, isError, message } = useSelector(
    (state) => state.stats
  )
  
  useEffect(() => {
    dispatch(getStats())

    return () => {
      dispatch(resetStats())
    }
  }, [dispatch])
  console.log(statsList)


  return (
    <>
    <Helmet>
    <title>About | WildPermits</title>
    <meta name='About' content='About page for WildPermits.' />
    </Helmet>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0" />

    <section className={container}>
        <h1 className="headerh2">About WildPermits</h1>
        <div className={aboutgrid}>
            <div className= "aboutphotocontainer">
                <div className="aboutphoto">
                    <p>Michael Feger</p>
                    <a className="aboutphotoa">Creator of Wildpermits</a>
                </div>
            </div>
            <div className= "aboutgriditem">
                <h2 className="headerh2">Why?</h2>
                <p>I created this website as a tool to help everyone access our public land here in California.
                    With permits releasing 6 months out and countless lotteries to enter, our public lands are
                    becoming further out of reach for many. While limiting access is important for 
                    conservation efforts, I see no reason why people can't have an easier way
                    to secure cancelled permits. There are a few paid versions of this service, but I wanted
                    to create the first free version.
                </p>
            </div>
        </div>
        <div className = {howitworks}>
            <h2 className="headerh2">How it works</h2>
            <p>This app uses Recreation.gov API. It takes information from their permits pages
                on each park and records each availability. It then stores all of this into a database,
                then references each permit tracker looking for dates/permits that you requested. It then sends
                out text messages. It saves each record of messages sent and will not notify you again 
                until the requested permit falls below your desired group size and comes back up. The 
                website itself is a simple React app built with MongoDB.
            </p>
        </div>
        <br></br>
        <h2 className="headerh2">Since Launch in September 2022</h2>
        <div className = "aboutgrid2">
            <div className = "aboutgriditem">
                <span class="material-symbols-outlined">
                    person
                </span>
                <h2 className= "aboutstats">1,000+ Users<br/>Registered</h2>
            </div>
            <div className = "aboutgriditem">
                <span class="material-symbols-outlined">
                    vibration
                </span>
                <h2 className= "aboutstats">
                {statsList.length > 0 ? (
                    <>
                    <a className = "aboutstats">{statsList[0].totaltext.toLocaleString("en-US")} Notifications</a>
                    </>
                    ) : (
                    <>
                    <a className = "aboutstats"></a>
                    </>
                    )}<br></br>Sent</h2>
            </div>
        </div>
        <div className= "aboutgriditemfaq">
            <h2 className="headerh2">FAQ</h2>
            <p2>Will you add more parks?</p2>
            <p>I plan to expand to Oregon and Washington by summer. 
                This comes with some challenges, I need to keep API calls to a 
                reasonable level to avoid rate limiting by Recreation.gov. I don't want
                to abuse their platform. Because of this, refresh rates for other states 
                may be slower than California.
            </p>
            <p2>Will you create a mobile app?</p2>
            <p>Yes, I do plan on creating a mobile app in the future. Though currently, 
                the website is fully responsive to mobile browsers.
            </p>
            <p2>How often do you check for cancellations?</p2>
            <p>The database is refreshed every minute.</p>
            <p2>Why is there sometimes not an available permit when I get a text?</p2>
            <p>While there are some bugs in the Recreation.gov API for places like The Enchantments, someone likely grabbed the permit before you.
                These permits are highly competitive and there will always be people monitoring the page for their trip.
            </p>
            <p2>How can I contribute?</p2>
            <p>I have a donation button at the top of the page. I am also open to
                working with other programmers. Email me at support@wildpermits.com
            </p>
            <p2>What do you do with the donation money?</p2>
            <p>All donation money goes to running the servers.</p>
        </div>


    </section>
    
    </>
  )
}

export default About
