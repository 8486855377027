import React, { useEffect } from "react";

function KingRangeRedirect() {

  useEffect(() => {
    window.location.href = "https://www.recreation.gov/permits/72192/registration/detailed-availability";
  }, []);

  return (
    <div>
      <h2>Redirecting to Recreation.gov</h2>
    </div>
  );
}

export default KingRangeRedirect;