import { useState, useEffect } from 'react'
import { FaSignInAlt } from 'react-icons/fa'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { login, reset } from '../features/auth/authSlice'
import Spinner from '../components/Spinner'
import {Helmet} from 'react-helmet'
//import background from "./20210815_104254.jpg";



function Login() {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  })

  const { email, password } = formData

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  )

  useEffect(() => {
    if (isError) {
      toast.error(message, {
        toastId: 'success1',
    })
    }

    if (isSuccess || user) {
      navigate('/dashboard')
    }

    dispatch(reset())
  }, [user, isError, isSuccess, message, navigate, dispatch])

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const onSubmit = (e) => {
    e.preventDefault()

    const userData = {
      email,
      password,
    }

    userData.email = userData.email.toLowerCase()


    dispatch(login(userData))
  }


  return (
    <>
    <Helmet>
    <title>Login | WildPermits</title>
    <meta name='Login' content='Login page for WildPermits.' />
    </Helmet>
    <div className='formcard'>
      <div className="formcard-image">
      <div className="overlaylogin"></div>
      <h2 class="card-heading">
				Welcome Back
				<small>Please Login to Your Account</small>
			</h2>
      </div>

      <section className='form'>
        <form onSubmit={onSubmit}>
          <br></br>
          <div className='inputreg'>
            <input
              type='email'
              className="input-fields"
              id='email'
              name='email'
              value={email.toLowerCase()}
              onChange={onChange}
              required
            />
            <label className="input-labels">Email</label>
            </div>
          <div className='inputreg'>
            <input
              type='password'
              className='input-fields'
              id='password'
              name='password'
              value={password}
              onChange={onChange}
              required
            />
            <label className="input-labels">Password</label>
          </div>
          <div className='form-group'>
            <button type='submit' className='btn2'>
              Submit
            </button>
            <li>
              <Link to='/forgot_pass'>
                <FaSignInAlt /> Forgot Password?
              </Link>
            </li>
          </div>
        </form>
      </section>
      </div>
    </>
  )
}

export default Login
