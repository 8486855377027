const options = [
    {label: "Alder Creek Zone: Yosemite",value:"Alder Creek"},
{label: "Aspen Valley Zone: Yosemite",value:"Aspen Valley"},
{label: "Base Line Camp Road Zone: Yosemite",value:"Base Line Camp Road"},
{label: "Beehive Meadows Zone: Yosemite",value:"Beehive Meadows"},
{label: "Bridalveil Creek Zone: Yosemite",value:"Bridalveil Creek"},
{label: "Budd Creek (cross-country only) Zone: Yosemite",value:"Budd Creek (cross-country only)"},
{label: "Cathedral Lakes Zone: Yosemite",value:"Cathedral Lakes"},
{label: "Chilnualna Falls Zone: Yosemite",value:"Chilnualna Falls"},
{label: "Cottonwood Creek Zone: Yosemite",value:"Cottonwood Creek"},
{label: "Deer Camp Zone: Yosemite",value:"Deer Camp"},
{label: "Gaylor Creek/Lake (cross-country only) Zone: Yosemite",value:"Gaylor Creek/Lake (cross-country only)"},
{label: "Glacier Point->Illilouette Zone: Yosemite",value:"Glacier Point->Illilouette"},
{label: "Glacier Point->Little Yosemite Valley Zone: Yosemite",value:"Glacier Point->Little Yosemite Valley"},
{label: "Glen Aulin Zone: Yosemite",value:"Glen Aulin"},
{label: "Glen Aulin->Cold Canyon/Waterwheel (pass through) Zone: Yosemite",value:"Glen Aulin->Cold Canyon/Waterwheel (pass through)"},
{label: "Happy Isles->Illilouette (No Donohue Pass) Zone: Yosemite",value:"Happy Isles->Illilouette (No Donohue Pass)"},
{label: "Happy Isles->Little Yosemite Valley (No Donohue Pass) Zone: Yosemite",value:"Happy Isles->Little Yosemite Valley (No Donohue Pass)"},
{label: "Happy Isles->Past LYV (Donohue Pass Eligible) Zone: Yosemite",value:"Happy Isles->Past LYV (Donohue Pass Eligible)"},
{label: "Luken->Lukens Lake Zone: Yosemite",value:"Luken->Lukens Lake"},
{label: "Lukens Lake->Yosemite Creek Zone: Yosemite",value:"Lukens Lake->Yosemite Creek"},
{label: "Lyell (No Donohue Pass) Zone: Yosemite",value:"Lyell (No Donohue Pass)"},
{label: "Lyell Canyon (Donohue Pass Eligible) Zone: Yosemite",value:"Lyell Canyon (Donohue Pass Eligible)"},
{label: "Mather Ranger Station Zone: Yosemite",value:"Mather Ranger Station"},
{label: "May Lake Zone: Yosemite",value:"May Lake"},
{label: "May Lake->Snow Creek Zone: Yosemite",value:"May Lake->Snow Creek"},
{label: "McGurk Meadow Zone: Yosemite",value:"McGurk Meadow"},
{label: "Miguel Meadow Zone: Yosemite",value:"Miguel Meadow"},
{label: "Mirror Lake->Snow Creek Zone: Yosemite",value:"Mirror Lake->Snow Creek"},
{label: "Mono Meadow Zone: Yosemite",value:"Mono Meadow"},
{label: "Mono/Parker Pass Zone: Yosemite",value:"Mono/Parker Pass"},
{label: "Murphy Creek Zone: Yosemite",value:"Murphy Creek"},
{label: "Nelson Lake (cross-country only) Zone: Yosemite",value:"Nelson Lake (cross-country only)"},
{label: "Old Big Oak Flat Road Zone: Yosemite",value:"Old Big Oak Flat Road"},
{label: "Ostrander (Lost Bear Meadow) Zone: Yosemite",value:"Ostrander (Lost Bear Meadow)"},
{label: "Pohono Trail (Glacier Point) Zone: Yosemite",value:"Pohono Trail (Glacier Point)"},
{label: "Pohono Trail (Taft Point) Zone: Yosemite",value:"Pohono Trail (Taft Point)"},
{label: "Pohono Trail (Wawona Tunnel/Bridalveil Parking) Zone: Yosemite",value:"Pohono Trail (Wawona Tunnel/Bridalveil Parking)"},
{label: "Poopenaut Valley Zone: Yosemite",value:"Poopenaut Valley"},
{label: "Porcupine Creek Zone: Yosemite",value:"Porcupine Creek"},
{label: "Rafferty Creek->Vogelsang Zone: Yosemite",value:"Rafferty Creek->Vogelsang"},
{label: "Rancheria Falls Zone: Yosemite",value:"Rancheria Falls"},
{label: "Rockslides (cross-country only) Zone: Yosemite",value:"Rockslides (cross-country only)"},
{label: "Smith Peak Zone: Yosemite",value:"Smith Peak"},
{label: "South Fork of Tuolumne River Zone: Yosemite",value:"South Fork of Tuolumne River"},
{label: "Sunrise (No Donohue Pass) Zone: Yosemite",value:"Sunrise (No Donohue Pass)"},
{label: "Tamarack Creek Zone: Yosemite",value:"Tamarack Creek"},
{label: "Ten Lakes Zone: Yosemite",value:"Ten Lakes"},
{label: "Westfall Meadow Zone: Yosemite",value:"Westfall Meadow"},
{label: "White Wolf Campground Zone: Yosemite",value:"White Wolf Campground"},
{label: "White Wolf->Aspen Valley Zone: Yosemite",value:"White Wolf->Aspen Valley"},
{label: "White Wolf->Pate Valley Zone: Yosemite",value:"White Wolf->Pate Valley"},
{label: "White Wolf->Smith Meadow (including Harden Lake) Zone: Yosemite",value:"White Wolf->Smith Meadow (including Harden Lake)"},
{label: "Yosemite Creek Zone: Yosemite",value:"Yosemite Creek"},
{label: "Yosemite Falls Zone: Yosemite",value:"Yosemite Falls"},
{label: "Young Lakes via Dog Lake Zone: Yosemite",value:"Young Lakes via Dog Lake"},
{label: "Young Lakes via Glen Aulin Trail Zone: Yosemite",value:"Young Lakes via Glen Aulin Trail"},
{label: "Badger Pass (Winter Only) Zone: Yosemite",value:"Badger Pass (Winter Only)"},
{label: "Crane Flat (Winter Only) Zone: Yosemite",value:"Crane Flat (Winter Only)"},
{label: "Dewey Meadow (#18) (Winter Only) Zone: Yosemite",value:"Dewey Meadow (#18) (Winter Only)"},
{label: "Dewey Ridge (#14) (Winter Only) Zone: Yosemite",value:"Dewey Ridge (#14) (Winter Only)"},
{label: "Horizon Ridge (#15) (Winter Only) Zone: Yosemite",value:"Horizon Ridge (#15) (Winter Only)"},
{label: "Mariposa Grove (Winter Only) Zone: Yosemite",value:"Mariposa Grove (Winter Only)"},
{label: "Merced Crest (#16) (Winter Only) Zone: Yosemite",value:"Merced Crest (#16) (Winter Only)"},
{label: "Merced Grove (Winter Only) Zone: Yosemite",value:"Merced Grove (Winter Only)"},
{label: "Snow Creek Cabin (Winter Only) Zone: Yosemite",value:"Snow Creek Cabin (Winter Only)"},
{label: "Tioga Pass (Winter Only) Zone: Yosemite",value:"Tioga Pass (Winter Only)"},
{label: "Glacier Canyon Zone: Inyo",value:"Glacier Canyon"},
{label: "Parker Lake Zone: Inyo",value:"Parker Lake"},
{label: "Rush Creek Zone: Inyo",value:"Rush Creek"},
{label: "Yost / Fern Lakes Zone: Inyo",value:"Yost / Fern Lakes"},
{label: "River Trail Zone: Inyo",value:"River Trail"},
{label: "Minaret Lake Zone: Inyo",value:"Minaret Lake"},
{label: "Beck Lake Zone: Inyo",value:"Beck Lake"},
{label: "Fern Lake Zone: Inyo",value:"Fern Lake"},
{label: "Red Cones Zone: Inyo",value:"Red Cones"},
{label: "Cottonwood Pass Zone: Inyo",value:"Cottonwood Pass"},
{label: "Lundy Canyon (Non Quota) Zone: Inyo",value:"Lundy Canyon (Non Quota)"},
{label: "Golden Trout Lakes Zone: Inyo",value:"Golden Trout Lakes"},
{label: "Deer Lakes Zone: Inyo",value:"Deer Lakes"},
{label: "Duck Pass Zone: Inyo",value:"Duck Pass"},
{label: "Valentine Lake Zone: Inyo",value:"Valentine Lake"},
{label: "Laurel Lakes Zone: Inyo",value:"Laurel Lakes"},
{label: "Convict Creek Zone: Inyo",value:"Convict Creek"},
{label: "McGee Pass Zone: Inyo",value:"McGee Pass"},
{label: "Hilton Lakes Zone: Inyo",value:"Hilton Lakes"},
{label: "Tamarack Lakes Zone: Inyo",value:"Tamarack Lakes"},
{label: "Little Lakes Valley Zone: Inyo",value:"Little Lakes Valley"},
{label: "Italy Pass Zone: Inyo",value:"Italy Pass"},
{label: "Gable Lakes Zone: Inyo",value:"Gable Lakes"},
{label: "Horton Lakes Zone: Inyo",value:"Horton Lakes"},
{label: "Piute Pass Zone: Inyo",value:"Piute Pass"},
{label: "Tyee Lakes Zone: Inyo",value:"Tyee Lakes"},
{label: "Treasure Lakes Zone: Inyo",value:"Treasure Lakes"},
{label: "Bishop Pass -South Lake Zone: Inyo",value:"Bishop Pass -South Lake"},
{label: "Baker and Green Lakes Zone: Inyo",value:"Baker and Green Lakes"},
{label: "Big Pine Creek South Fork Zone: Inyo",value:"Big Pine Creek South Fork"},
{label: "Birch Lake Zone: Inyo",value:"Birch Lake"},
{label: "Red Lake Zone: Inyo",value:"Red Lake"},
{label: "Baxter Pass Zone: Inyo",value:"Baxter Pass"},
{label: "Kearsarge Pass Zone: Inyo",value:"Kearsarge Pass"},
{label: "Shepherd Pass Zone: Inyo",value:"Shepherd Pass"},
{label: "George Creek Zone: Inyo",value:"George Creek"},
{label: "Meysan Lake Zone: Inyo",value:"Meysan Lake"},
{label: "Kennedy Meadows (Non Quota) Zone: Inyo",value:"Kennedy Meadows (Non Quota)"},
{label: "Blackrock (Non Quota) Zone: Inyo",value:"Blackrock (Non Quota)"},
{label: "Fish Creek Zone: Inyo",value:"Fish Creek"},
{label: "Trail Pass (Non Quota) Zone: Inyo",value:"Trail Pass (Non Quota)"},
{label: "John Muir Trail South of Devils Postpile Zone: Inyo",value:"John Muir Trail South of Devils Postpile"},
{label: "Taboose Pass Zone: Inyo",value:"Taboose Pass"},
{label: "Sawmill Pass Zone: Inyo",value:"Sawmill Pass"},
{label: "Haiwee Pass (Non Quota) Zone: Inyo",value:"Haiwee Pass (Non Quota)"},
{label: "Pine Creek Zone: Inyo",value:"Pine Creek"},
{label: "Sabrina Lake Zone: Inyo",value:"Sabrina Lake"},
{label: "George Lake  Zone: Inyo",value:"George Lake "},
{label: "High Trail Zone: Inyo",value:"High Trail"},
{label: "John Muir Trail North of Devils Postpile Zone: Inyo",value:"John Muir Trail North of Devils Postpile"},
{label: "Shadow Creek Zone: Inyo",value:"Shadow Creek"},
{label: "Lakes Canyon (Non Quota) Zone: Inyo",value:"Lakes Canyon (Non Quota)"},
{label: "Upper Buttermilk Zone: Inyo",value:"Upper Buttermilk"},
{label: "Mono Pass Zone: Inyo",value:"Mono Pass"},
{label: "Big Pine Creek North Fork Zone: Inyo",value:"Big Pine Creek North Fork"},
{label: "Big Pine Creek North Fork (Pack Outfit) Zone: Inyo",value:"Big Pine Creek North Fork (Pack Outfit)"},
{label: "Big Pine Creek North Fork (Outfitter) Zone: Inyo",value:"Big Pine Creek North Fork (Outfitter)"},
{label: "Lamarck Lakes Zone: Inyo",value:"Lamarck Lakes"},
{label: "Warren Fork (Non Quota) Zone: Inyo",value:"Warren Fork (Non Quota)"},
{label: "Saddlebag Lake (Non Quota) Zone: Inyo",value:"Saddlebag Lake (Non Quota)"},
{label: "Gibbs Lake Zone: Inyo",value:"Gibbs Lake"},
{label: "North Fork of Lone Pine Creek Zone: Inyo",value:"North Fork of Lone Pine Creek"},
{label: "Mt. Whitney (Trail Crest Exit) Zone: Inyo",value:"Mt. Whitney (Trail Crest Exit)"},
{label: "Bloody Canyon Zone: Inyo",value:"Bloody Canyon"},
{label: "Olancha Pass (Non Quota) Zone: Inyo",value:"Olancha Pass (Non Quota)"},
{label: "Tuttle Creek Zone: Inyo",value:"Tuttle Creek"},
{label: "Cottonwood Lakes Zone: Inyo",value:"Cottonwood Lakes"},
{label: "Lewis Creek Zone: Seki",value:"Lewis Creek"},
{label: "Copper Creek Zone: Seki",value:"Copper Creek"},
{label: "Woods Creek Zone: Seki",value:"Woods Creek"},
{label: "Bubbs Creek Zone: Seki",value:"Bubbs Creek"},
{label: "Redwood Canyon Zone: Seki",value:"Redwood Canyon"},
{label: "Sugarloaf Zone: Seki",value:"Sugarloaf"},
{label: "Belle Canyon Zone: Seki",value:"Belle Canyon"},
{label: "JO Pass Zone: Seki",value:"JO Pass"},
{label: "Lakes Trail Zone: Seki",value:"Lakes Trail"},
{label: "Alta Zone: Seki",value:"Alta"},
{label: "High Sierra Trail Zone: Seki",value:"High Sierra Trail"},
{label: "Middle Fork Kaweah Zone: Seki",value:"Middle Fork Kaweah"},
{label: "Ladybug Zone: Seki",value:"Ladybug"},
{label: "Garfield Grove Zone: Seki",value:"Garfield Grove"},
{label: "Paradise Ridge Zone: Seki",value:"Paradise Ridge"},
{label: "Timber Gap Zone: Seki",value:"Timber Gap"},
{label: "Sawtooth Pass Zone: Seki",value:"Sawtooth Pass"},
{label: "Atwell-Hockett Zone: Seki",value:"Atwell-Hockett"},
{label: "Tar Gap Zone: Seki",value:"Tar Gap"},
{label: "Mosquito Lakes Zone: Seki",value:"Mosquito Lakes"},
{label: "Eagle Lake Zone: Seki",value:"Eagle Lake"},
{label: "White Chief Zone: Seki",value:"White Chief"},
{label: "Farewell Gap Zone: Seki",value:"Farewell Gap"},
{label: "Franklin Zone: Seki",value:"Franklin"},
{label: "Lakes Trail Pass Through Zone: Seki",value:"Lakes Trail Pass Through"},
{label: "Colony Mill East Zone: Seki",value:"Colony Mill East"},
{label: "Colony Mill West Zone: Seki",value:"Colony Mill West"},
{label: "Don Cecil East Zone: Seki",value:"Don Cecil East"},
{label: "Don Cecil West Zone: Seki",value:"Don Cecil West"},
{label: "North Fork Kaweah Zone: Seki",value:"North Fork Kaweah"},
{label: "Oriole Lake Zone: Seki",value:"Oriole Lake"},
{label: "Twin Lakes Zone: Seki",value:"Twin Lakes"},
{label: "Snow Zone Zone: Enchantments",value:"Snow Zone"},
{label: "Stuart Zone Zone: Enchantments",value:"Stuart  Zone"},
{label: "Stuart Zone (stock) Zone: Enchantments",value:"Stuart Zone (stock)"},
{label: "Eightmile/Caroline Zone Zone: Enchantments",value:"Eightmile/Caroline Zone"},
{label: "Eightmile/Caroline Zone (stock) Zone: Enchantments",value:"Eightmile/Caroline Zone (stock)"},
{label: "Colchuck Zone Zone: Enchantments",value:"Colchuck Zone"},
{label: "Core Enchantment Zone Zone: Enchantments",value:"Core Enchantment Zone"},
{label: "11 Camper Flat Zone: Desolation",value:"11 Camper Flat"},
{label: "03 Genevieve Zone: Desolation",value:"03 Genevieve"},
{label: "04 Grouse Lakes Zone: Desolation",value:"04 Grouse Lakes"},
{label: "12 Phipps Zone: Desolation",value:"12 Phipps"},
{label: "01 Rockbound Lake Zone: Desolation",value:"01 Rockbound Lake"},
{label: "05 Brown Mountain Zone: Desolation",value:"05 Brown Mountain"},
{label: "10 Lelands Zone: Desolation",value:"10 Lelands"},
{label: "14 Schmidell Zone: Desolation",value:"14 Schmidell"},
{label: "43 Lyons Zone: Desolation",value:"43 Lyons"},
{label: "44 Avalanche Zone: Desolation",value:"44 Avalanche"},
{label: "07 Stony Ridge Zone: Desolation",value:"07 Stony Ridge"},
{label: "42 Triangle Zone: Desolation",value:"42 Triangle"},
{label: "40 Lake of Woods Zone: Desolation",value:"40 Lake of Woods"},
{label: "09 Highland Zone: Desolation",value:"09 Highland"},
{label: "13 Lakes #3 and #5 Zone: Desolation",value:"13 Lakes #3 and #5"},
{label: "02 General Creek Zone: Desolation",value:"02 General Creek"},
{label: "06 Rubicon Zone: Desolation",value:"06 Rubicon"},
{label: "45 Ralston Zone: Desolation",value:"45 Ralston"},
{label: "08 Tells Zone: Desolation",value:"08 Tells"},
{label: "19 Lawrence Zone: Desolation",value:"19 Lawrence"},
{label: "20 Red Peak Zone: Desolation",value:"20 Red Peak"},
{label: "22 China Flat Zone: Desolation",value:"22 China Flat"},
{label: "23 Dick's Lake Zone: Desolation",value:"23 Dick's Lake"},
{label: "24 Kalmia Zone: Desolation",value:"24 Kalmia"},
{label: "15 Dick's Peak Zone: Desolation",value:"15 Dick's Peak"},
{label: "16 Middle Velma Zone: Desolation",value:"16 Middle Velma"},
{label: "17 Lower Velmas Zone: Desolation",value:"17 Lower Velmas"},
{label: "21 Lois Zone: Desolation",value:"21 Lois"},
{label: "18 Eagle Zone: Desolation",value:"18 Eagle"},
{label: "25 Maude Zone: Desolation",value:"25 Maude"},
{label: "26 Rockbound Pass Zone: Desolation",value:"26 Rockbound Pass"},
{label: "27 Mt. Price Zone: Desolation",value:"27 Mt. Price"},
{label: "28 Half Moon Zone: Desolation",value:"28 Half Moon"},
{label: "29 Gilmore Zone: Desolation",value:"29 Gilmore"},
{label: "30 Cathedral Zone: Desolation",value:"30 Cathedral"},
{label: "31 Tyler Zone: Desolation",value:"31 Tyler"},
{label: "32 Twin Zone: Desolation",value:"32 Twin"},
{label: "33 Aloha Zone: Desolation",value:"33 Aloha"},
{label: "34 Susie Zone: Desolation",value:"34 Susie"},
{label: "35 Grass Zone: Desolation",value:"35 Grass"},
{label: "36 Hemlock Zone: Desolation",value:"36 Hemlock"},
{label: "37 Smith Zone: Desolation",value:"37 Smith"},
{label: "38 Waca Zone: Desolation",value:"38 Waca"},
{label: "39 American Zone: Desolation",value:"39 American"},
{label: "41 Tamarack Zone: Desolation",value:"41 Tamarack"},
{label: "Pacific Crest Trail Zone: Desolation",value:"Pacific Crest Trail"},
{label: "Tahoe Rim Trail Zone: Desolation",value:"Tahoe Rim Trail"},
{label: "King Range(Lost Coast) Zone: KingsRange",value:"King Range"},
{label: "Mt. Whitney Trail (Overnight)Zone: Whitney",value:"Mt. Whitney Trail (Overnight)"},
{label: "Mt. Whitney Day Use (All Routes) Zone: Whitney",value:"Mt. Whitney Day Use (All Routes)"},



    

]

export {options};