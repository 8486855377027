import axios from 'axios'

const API_URL = '/api/stats/'


const getStats = async () => {
  const response = await axios.get(API_URL)
  return response.data
}

const statsService = {
  getStats,
}

export default statsService